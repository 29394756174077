.testimonial-bottom-padding {
  padding-bottom: 50px;
}

.testimonial__section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.testimonial__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}

.testimonial__top-level {
  display: flex;
  flex-direction: row;
  align-items: left;
  margin: auto;
  width: 100%;
  max-width: 1300px;
}

.testimonial__heading-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.testimonial__heading {
  line-height: 1.28571;
  letter-spacing: -0.4px;
  width: 100%;
}

.testimonial__heading-img > img {
  height: 30vh;
}

.testimonial__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100vw;
}

.testimonial__container-row {
  /* display: flex; */
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 40px;
}

.testimonial__slideshow {
  /* max-width: 50vw; */
  /* display: block; */
}

.testimonial__slideshow-slider {
  white-space: nowrap;
  transition: ease 2000ms;
}

/* .testimonial__slideshow-slider > div {
  display: inline-block;
  white-space: normal;
  opacity: 0.4;
} */

.testimonial__slideshow-slider > div.active {
  opacity: 1;
}

.testimonial__slideshow-slider-indicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.testimonial__slideshow-slider-indicator-dots {
  height: 20px;
  width: 20px;
  margin: 20px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.testimonial__slideshow-slider-indicator-dots::before {
  content: "";
  height: 10px;
  width: 10px;
  background-color: #d4d4d4;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.testimonial__slideshow-slider-indicator-dots.active::before,
.testimonial__slideshow-slider-indicator-dots:hover::before {
  background-color: var(--primary);
  background-image: linear-gradient(135deg, var(--primary) 0%, var(--primary) 100%);
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

@media screen and (max-width: 960px) {
  .testimonial__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .testimonial__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .testimonial__top-level {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }

  .testimonial__top-level > * {
    padding: 0;
  }

  .testimonial__slideshow {
    max-width: 100vw;
  }

  .testimonial-card-client-wrapper {
    width: 80%;
  }

  .testimonial__slideshow .testimonial__slideshow-slider {
    padding-bottom: 30px !important;
  }

  .testimonial__slideshow-slider > .card > .card-container {
    /* height: 50vh; */
  }
}
