@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"), url(fonts/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Light";
  src: local("Poppins-Light"), url(fonts/Poppins-Light.ttf) format("truetype");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins-Medium', sans-serif;
}

body {
  font-size: 18px;
  line-height: 1.77778;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 960px) {
  body {
    font-size: 16px;
    line-height: 1.75;
  }
}
