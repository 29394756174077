.about__section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1300px;
  margin: 0 auto;
  width: 100%;
}

.aboutBg {
  /* background-image: url("images/about-bg.svg"); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}

.aboutBg2 {
  /* background-image: url("images/about-sec2-bg.svg"); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}

.static-height {
  height: 998px;
}

.about-wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.about__content-section {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0 auto;
  padding-bottom: 100px;
  max-width: 1300px;
}

.about__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.about__container-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 40px;
}

.about__container-col {
  display: flex;
  flex: 0 0 33.33%;
}

.about__container-col > .card > .card-container {
  height: 520px;
}

.stage {
  position: relative;
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 1.4;
  font-weight: 500;
  letter-spacing: 1px;
  color: var(--primary);
}

.about__heading {
  margin-bottom: 24px;
}

.heading-bg {
  position: absolute;
  top: 0;
  border-radius: 25px 25px 50% 50%;
  width: 100%;
  left: 0;
  height: 150px;
  padding: 10px 50px 10px 20px;
  opacity: 0.85;
}

.heading-bg h2 {
  color: var(--secondary-light) !important;
  font-size: 36px !important;
}

.spacer {
  height: 180px;
}

.transparent-fill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about__detail {
  width: 100%;
}

.about__sec2 {
  padding: 0 0 80px 0;
  overflow-wrap: anywhere;
}

.about__container-col-extended {
  display: flex;
  flex-direction: column;

  /* padding: 0 0 0 60px; */
}

.poppinsMedium {
  font-family: 'Poppins-medium';
}

.flex-1 {
  flex: 0 0 60%;
}

.flex-2 {
  flex: 0 0 40%;
}

.about__container-col-extended h2 {
  padding: 15px 0;
  color: inherit;
}

.about__container-col-extended h4 {
  color: inherit;
  margin: 20px 20px 20px 0;
}

/* .about__container-col-extended > p {
  color: #ccc;
  padding: 15px 100px 15px 0;
} */

.about__card {
  margin: 0;
}

.about__card > span {
  font-size: 28px;
  color: var(--primary);
  opacity: 0.7;
  font-family: 'Poppins-medium';
  padding-bottom: 30px;
}

.about__bullets {
  display: flex;
  flex-direction: row;
}

.about__bullets > .bullets {
  margin-top: 25px;
  margin-right: 20px;
  font-family: 'Poppins-medium';
}

.bullets li {
  display: flex;
  font-family: 'Poppins-medium';
  color: #262530;
  list-style-type: circle;
  margin: 10px 0 23px 0;
  flex-wrap: nowrap;
  font-size: 20px;
}

.bullets li > span {
  width: 30px;
  font-size: initial;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  font-family: 'Poppins-medium';
}

/* .bullet-check {
  width: 24px;
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #2285ef;
  border: 1px solid #2285ef;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-right: 12px;
  color: #fff;
} */

.bullets .MuiSvgIcon-root {
  font-size: 20px;
  margin-right: 15px;
  padding-top: 10px;
  color: var(--primary);
}

.inside-line {
  width: 60px;
  height: 2px;
  margin: 17px 8px;
}

@media screen and (max-width: 1200px) {
  .static-height {
    height: auto;
  }

  .about__container-col > .card > .card-container {
    height: 580px;
  }

  .bullet-text {
    width: 70%;
  }
}

@media screen and (max-width: 960px) {
  .about__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .about__content-section {
    margin: 0 0;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
  }

  .about__container-col {
    flex: 0 0 100%;
  }

  .about__container-col > .card > .card-container {
    height: auto;
  }

  .about__container-col-extended {
    padding: 0 0 0 0;
  }

  .about__container-row {
    flex-direction: column;
  }

  .about__heading {
    font-size: 30px;
  }

  .about__bullets {
    justify-content: center;
  }

  .about__bullets > .bullets {
    margin-right: 0;
  }

  .stage {
    font-size: 18px;
  }

  .heading-bg {
    padding: 10px 10px 10px 10px;
  }

  .heading-bg h2 {
    font-size: 21px !important;
    height: auto;
  }

  .spacer {
    height: 110px;
  }

  .about__card > span {
    font-size: 20px;
  }

  .bullets li {
    font-size: initial;
    margin: 10px 0;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .bullets li > span {
    font-family: 'Poppins-light';
  }

  .inside-line {
    width: 0;
    height: 0;
    margin: 0;
  }
}

@media screen and (max-width: 680px) {
  .about__bullets {
    flex-wrap: wrap;
  }

  .bullets {
    flex: 0 0 100%;
    flex-wrap: wrap;
  }

  /*
  .bullet-check {
    width: 3vw;
    height: 3vw;
  } */

  .bullets .MuiSvgIcon-root {
    font-size: 13px;
  }
}

.scroll-action {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  color: #111029;
  box-shadow: 0 5px 20px rgb(17 16 41 / 5%);
}

.scroll-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  margin-right: 24px;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 5px 20px rgb(17 16 41 / 5%);
  box-shadow: 0 5px 20px rgb(17 16 41 / 5%);
  font-size: 0;
  -webkit-transition: -webkit-transform 0.25s;
  transition: -webkit-transform 0.25s;
  -o-transition: transform 0.25s;
}

.scroll-text {
  color: #fff;
}
