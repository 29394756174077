.home__section {
  /* overflow-x: hidden; */
  font-family: 'Poppins-Medium', sans-serif;
}

.combinedBg {
  background-image: url("images/combined-bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-position-y: 652px;
  max-width: 2000px;
  margin: auto;
}

.combinedBg2 {
  background-image: url("images/combined-bg2.svg");
  background-repeat: no-repeat;
  background-size: 115vw;
  background-position: center;
  background-position-y: 77vh;
  max-width: 2000px;
  margin: auto;
}

.fillBg {
  width: 100%;
  height: 100vh;
  z-index: -2;
  position: fixed;
}

.w-100 {
  width: 100% !important;
}

.fadeIn {
  animation: fadeIn ease-in 0.5s forwards;
}

.scrollNone {
  overflow: hidden;
}

@keyframes fadeIn {
  0% {
    transform: translate(-10%, 0%);
    opacity: 0;
  }

  10% {
    transform: translate(-9%, 0%);
    opacity: 0.1;
  }

  20% {
    transform: translate(-8%, 0%);
    opacity: 0.2;
  }

  30% {
    transform: translate(-7%, 0%);
    opacity: 0.3;
  }

  40% {
    transform: translate(-6%, 0%);
    opacity: 0.4;
  }

  50% {
    transform: translate(-5%, 0%);
    opacity: 0.5;
  }

  60% {
    transform: translate(-4%, 0%);
    opacity: 0.6;
  }

  70% {
    transform: translate(-3%, 0%);
    opacity: 0.7;
  }

  80% {
    transform: translate(-2%, 0%);
    opacity: 0.8;
  }

  90% {
    transform: translate(-1%, 0%);
    opacity: 0.9;
  }

  100% {
    transform: translate(0%, 0%);
    opacity: 1;
  }
}

@media screen  and (min-width: 961px) {
  .combinedBg {
    background-position-y: bottom;
  }
}
@media screen and (max-width: 960px) {
  .combinedBg {
    background-image: none;
  }
}
