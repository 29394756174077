.card {
  margin: 50px 20px;
  max-width: 100%;
  max-height: 100%;
}

.card-container {
  /* background: #fff; */
  background: rgba(255, 255, 255, 0.7);
  box-shadow: #a7dad1 5px 5px 6px;

  /* box-shadow: 0 4px 40px rgba(196, 195, 195, 25%); */
  text-decoration: none;
  border-radius: 25px;
  padding: 30px;
}

.card-container:hover {
  transform: scale(1.06);
  transition: all 0.3s ease-out;
  color: var(--primary-dark);
}

.card-container-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-heading {
  color: inherit;
  margin-top: 30px;
}

.card-description {
  color: #656161;
  font-family: 'Poppins-light', sans-serif;
  margin-top: 24px;
}

.card-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  overflow: hidden;
  flex-shrink: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: -65px;
}

.card-image img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: auto;
}

.card-action-btn {
  margin-top: 30px;
}

@media screen and (max-width: 991px) {
  .card-image {
    height: 130px;
  }
}

/* Testimonial Cards */

.card-quotes-icon > .MuiSvgIcon-root {
  font-size: 100px;
  color: var(--primary);
  opacity: 0.5;
  transform: rotate(180deg);
}

.testimonial-card-quote-wrapper {
  padding: 0 20px 20px 20px;
}

.testimonial-card-client-wrapper {
  /* font-weight: 900; */
  position: absolute;
  padding: 20px;
  width: fit-content;
  border-radius: 25px;
}
