.team__section {
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
}

.team-bg {
  /* background-image: url("images/team-bg.svg"); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}

.team__wrapper {
  /* display: flex; */
  flex-direction: column;
  align-items: start;
  margin: 0 auto;
  padding-bottom: 100px;
  max-width: 1300px;
}

.team__container {
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

/* .team__container-row,
.team__slideshow,
.team__slideshow-slider {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 40px;
} */

.team__container-col {
  /* display: flex; */
  /* flex: 0 0 25%; */
  padding-top: 180px;
}

.team__heading {
  margin-bottom: 24px;
}

.team-card-name {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  font-size: 21px;
  margin-top: 0;
  background: linear-gradient(90deg, #f5fcfb 20%, #a1ecdf 100%);
}

.team__detail {
  width: 100%;
}

.team-desc {
  font-size: 13px;
}

.team__card-bg {
  height: 380px;
}

.team__card-bg:hover {
  background: #fff;
  box-shadow: 0 4px 40px rgb(196 195 195 / 25%);
  transform: scale(1);
  transition: all 0.5s ease;
}

.team__card-image {
  margin-top: 10px;
  width: 100%;
  height: auto;
}

.team__card-container-bg {
  background: linear-gradient(90deg, #f0faf5 20%, #e0f1ef 100%);
  width: 100%;
  height: auto;
  border-radius: 25px;
  margin-top: -260px;
}

.team__card-bg:hover .team__card-image {
  transform: scale(1.1);
  transition: all 0.5s ease;
}

.team__btn {
  opacity: 0;
}

.team__card-bg:hover .team__btn {
  opacity: 1;
  transition: all 0.6s ease-in-out;
}

@media screen and (max-width: 1200px) {
  .team__card-bg {
    height: 480px;
  }
}

@media screen and (max-width: 960px) {
  .team__container {
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .team__wrapper {
    margin: 0 0;
    /* display: flex; */
    flex-direction: column;
    padding-bottom: 30px;

    /* align-items: center; */
  }

  .team__heading {
    align-items: center;
    width: 100%;
    font-size: 30px;
  }

  .team__card-bg {
    height: 400px;
  }

  .team__container-col {
    /* flex: 0 0 49%; */
  }

  .team__container-row {
    flex-direction: row;
  }

  .team__slideshow {
    /* display: flex; */
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: unset;
    max-width: 100vw;
    overflow-x: hidden;
  }

  .team__slideshow-slider {
    flex-wrap: nowrap;
    white-space: nowrap;
    transition: ease 2000ms;
    display: flex;
    justify-content: unset;
  }

  .team__slideshow-slider > div {
    display: inline-block;
    white-space: normal;
    opacity: 0.4;
  }

  .team__slideshow-slider > div.active {
    opacity: 1;
  }

  .team__slideshow-slider-indicator {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .team__slideshow-slider-indicator-dots {
    height: 20px;
    width: 20px;
    margin: 20px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .team__slideshow-slider-indicator-dots::before {
    content: "";
    height: 10px;
    width: 10px;
    background-color: #d4d4d4;
    border-radius: 50%;
    transition: background-color 0.3s ease;
  }

  .team__slideshow-slider-indicator-dots.active::before,
  .team__slideshow-slider-indicator-dots:hover::before {
    background-color: var(--primary);
    background-image: linear-gradient(135deg, var(--primary) 0%, var(--primary) 100%);
    transform: scale(1.3);
    transition: transform 0.3s ease;
  }

  @media screen and (max-width: 600px) {
    .team__container-col {
      flex: 0 0 100%;
    }

    .team__card-bg {
      height: auto;
    }
  }
}
