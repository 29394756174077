.home__hero-section {
  color: black;
}

.hero-pd {
  padding-top: 160px !important;
}

.home__hero-row {
  align-items: center;
}

.container-sec {
  z-index: 1;
  width: 100%;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  padding-bottom: 39px;
}

.row {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  align-content: stretch;
}

.col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.home__hero-text-wrapper {
  /* max-width: 540px; */
  padding-top: 0;
  padding-bottom: 60px;
}

.top-line {
  width: 150px;
  height: 4px;
  margin-bottom: 15px;
}

.top-line:hover {
  transform: scale(1);
}

.heading {
  margin-bottom: 24px;
  font-size: 43px;
  line-height: 1.1;
}

.heading > .primary-color {
  font-weight: 500;
}

.dark {
  color: var(--primary-dark);
}

.heroBg {
  background-image: url("images/hero-bg.svg");
  background-repeat: no-repeat;

  /* background-size: cover; */
  background-position: top right;
  height: 800px;
  width: 100%;
  position: absolute;
  z-index: -1;
  max-width: 2000px;
  margin: auto;
  left: 0;
  right: 0;
}

.home__hero-subtitle {
  /* max-width: 440px; */
  margin-bottom: 35px;
  font-size: 22px;
  font-family: 'Poppins-light', 'Poppins-Medium', sans-serif;
  font-weight: 300;
  line-height: 24px;
}

.home__hero-img-wrapper {
  /* max-width: 555px; */
}

.diploma-translation {
  right: -17%;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

@media screen and (max-width: 991px) {
  .container-sec {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .heroBg {
    background-image: none;
  }

  .home__hero-text-wrapper {
    padding-bottom: 30px;
  }

  .col {
    max-width: 100%;
    flex-basis: 100%;
  }
}
