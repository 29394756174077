.clients__section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.clientBg {
  /* background-image: url("images/client-bg.svg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.clients__wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;

  /* max-width: 1300px; */
}

.clients__panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}

.clients__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  max-width: 70vw;
  overflow: hidden;
  margin: auto;
}

.clients__container-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 100px;
  white-space: nowrap;
  transition: ease 1000ms;
}

.client__slideshow {
  width: 100%;
  max-width: 100vw;
}

.client__slideshow-slider {
  white-space: nowrap;
  transition: ease 2000ms;
}

.slider-comp {
  display: inline-block;
  white-space: normal;
  opacity: 0.4;
}

.slider-comp.active {
  opacity: 1;
}

.clients__container-col {
  padding: 0 40px 80px 40px;
  min-width: 100px;
  white-space: normal;
  text-align: center;
  display: flex;
  opacity: 1;
  flex-wrap: wrap;
}

.clients__container-col-internal > img {
  height: 25vh;
}

.clients__container-col-internal {
  padding: 0 40px 80px 40px;
  min-width: 100px;
  white-space: normal;
  text-align: center;
  flex: 0 0 33.33%;
}

.clients__heading {
  color: #1c2237;
  margin-bottom: 24px;
  width: 65vw;
}

@media screen and (max-width: 960px) {
  .clients__wrapper {
    margin: 0 0;
    display: flex;
    flex-direction: column;
  }

  .clients__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    max-width: 100%;
  }

  .clients__container-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
    margin: 0;
  }

  .client__slideshow {
    max-width: 100%;
  }

  .client__slideshow-slider {
    white-space: nowrap;
    transition: ease 1000ms;
    display: inline-block;
  }

  .slider-comp {
    display: inline-block;
    white-space: normal;
    opacity: 1;
  }

  .slider-comp.active {
    opacity: 1;
  }

  .clients__container-col {
    flex: 0 0 100%;
    padding: 0;
    min-width: 50px;
  }

  .clients__container-col-internal {
    flex: 0 0 50%;
    padding: 10px;
  }

  .clients__top-line {
    font-size: 30px;
  }

  .clients__heading {
    width: 100%;
  }
}
