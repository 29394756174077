:root {
  --primary-dark: #00113b;
  --primary: #19bc9d;
  --secondary: #f00946;
  --secondary-light: #f46f3b;
  --main-bg: #f8fffe;
  --dark-bg: #2d2d2d;
}

.main-bg {
  background: var(--main-bg) !important;
}

.primary {
  background-color: var(--primary) !important;
  color: #fff !important;
  border: none !important;
}

.primary:hover {
  background-color: #fff !important;
  color: var(--primary) !important;
  border: none !important;
}

.primary div {
  color: #fff !important;
}

.primary-color {
  color: var(--primary);
}

.primary-dark div {
  color: #fff !important;
}

.primary-gradient div {
  color: #fff !important;
}

.primary:hover div {
  color: var(--primary) !important;
}

.primary-dark {
  background-color: var(--primary-dark) !important;
  color: #fff !important;
  border: none !important;
}

.primary-dark:hover {
  background-color: #fff !important;
  color: var(--primary) !important;
  border: none !important;
}

.primary-dark:hover div {
  color: var(--primary) !important;
}

.primary-gradient {
  background: linear-gradient(40deg, #bdf5ea 20%, var(--primary) 100%);
  color: #000 !important;
  border: none !important;
}

.primary-gradient:hover {
  background-color: #fff !important;
  color: var(--primary) !important;
  border: none !important;
}

.primary-gradient:hover div {
  color: var(--primary) !important;
}

.secondary {
  background-color: var(--secondary) !important;
  color: #fff !important;
  border: none !important;
}

.secondary:hover {
  background-color: #fff !important;
  color: var(--secondary) !important;
  border: none !important;
}

.secondary div {
  color: #fff !important;
}

.secondary:hover div {
  color: var(--secondary) !important;
}

.secondary-gradient {
  background: linear-gradient(90deg, var(--secondary-light) 0%, var(--secondary-light) 100%);
  color: #fff !important;
  border: none !important;
}

.secondary-gradient:hover {
  transform: scale(1.2);
  transition: 0.3s ease;
}

.secondary-gradient div {
  color: #fff !important;
}

.secondary-gradient:hover div {
  color: var(--secondary) !important;
}

.dark {
  background-color: var(--dark-bg) !important;
  color: #fff !important;
  border: none !important;
}

h1 {
  font-size: 62px;
  line-height: 1.16667;
  letter-spacing: 0.5px;
  color: #1c2237;
  font-weight: 500;
}

h2 {
  font-size: 40px;
  line-height: 1.16667;
  letter-spacing: 0.5px;
  color: #1c2237;
  font-weight: 500;
}

h3 {
  font-size: 35px;
  line-height: 1.16667;
  letter-spacing: 0.5px;
  color: #1c2237;
  font-weight: 500;

}

h4 {
  font-size: 20px;
  line-height: 1.16667;
  letter-spacing: 0.5px;
  color: #1c2237;
  font-weight: 500;

}

@media screen and (max-width: 960px) {
  h1 {
    font-size: 60px;
    letter-spacing: 0;
  }

  h2 {
    font-size: 40px;
    letter-spacing: 0;
  }

  h3 {
    font-size: 20px;
    letter-spacing: 0;
  }

  h4 {
    font-size: 18px;
  }
}

.no-wrap {
  flex-wrap: nowrap !important;
}

[data-aos="orbit"] {
  opacity: 0;
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1;
  }

  @media screen and (min-width: 960px) {
    &.aos-animate {
      transform: rotate(-45deg) translateX(-550px) rotate(45deg) translateX(-550px) rotate(45deg) translateX(-550px) rotate(45deg) translateX(-550px) rotate(45deg) translateX(-550px) rotate(45deg) translateX(-550px) rotate(45deg) translateX(-550px) rotate(45deg) translateX(-550px) rotate(45deg);
    }
  }

  @media screen and (max-width: 959px) {
    &.aos-animate {
      transform: translateX(-550px) rotate(45deg) translateX(-550px) rotate(45deg) translateX(-550px) rotate(45deg) translateX(-550px) rotate(45deg) translateX(-550px) rotate(45deg) translateX(-550px) rotate(45deg) translateX(-550px) rotate(45deg) translateX(-550px) rotate(45deg);
    }
  }
}
