.contact__section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* background: var(--dark-bg); */

  /* background: linear-gradient(158deg, var(--main-bg) 60%, var(--dark-bg) 40%); */
}

.pattern {
  position: relative;
  background-color: var(--main-bg);
  background-image:
    linear-gradient(
      315deg,
      var(--main-bg) 100%,
    );
  overflow: hidden;
}

.pattern::before {
  content: "";
  position: absolute;
  bottom: -13px;
  left: -1px;
  width: 101%;
  height: 360px;
  background:
    url(
      "images/curve.svg"
    );
  background-size: cover;
  background-repeat: no-repeat;
}

.contact__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.contact__top-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contact__top-info > .top-line {
  width: 80px;
}

.contact__card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 950px;
  background: var(--main-bg);
  box-shadow: 3px 3px 10px var(--dark-bg);
  text-decoration: none;
  border-radius: 34px;
  padding: 3vw;
  z-index: 1;
}

.contact__row {
  display: flex;
  flex: 0 0 50%;
  margin-left: 10px;
}

.card-detail {
  flex-direction: row;
}

.card-detail-image {
  display: flex;
  margin: auto;
  padding: 60px;
}

.card-detail > .card-info,
.card-detail > .card-detail-image {
  flex: 0 0 50%;
}

.contact__row > .card-info {
  display: flex;
  flex-direction: column;
}

.card-detail h1 {
  margin: 0 0 30px 0;
}

.card-detail p {
  color: var(--primary);
  font-size: 21px;
  width: 410px;
  font-family: "Poppins-Light", sans-serif;
  line-height: 32px;
}

.contact-detail {
  flex-direction: column;
  margin-top: 20px;
}

.contact-detail > div {
  display: flex;
  padding: 15px 15px 15px 0;
}

.contact-detail > div > .MuiSvgIcon-root {
  margin-right: 10px;
  color: var(--secondary);
  margin-top: auto;
}

.contact-detail span {
  font-family: "Poppins-Light", sans-serif;
}

.contact__email > img {
  height: 15px;
  margin: auto 15px auto -10px;
}

.contact__email > a,
.contact__location > a,
.contact__phone > a {
  text-decoration: none;
  color: black;
}

.contact__location > img {
  height: 25px;
  margin: auto 15px auto -10px;
}

.contact__phone > img {
  height: 22px;
  margin: auto 15px auto -10px;
}

@media screen and (max-width: 960px) {
  .contact__container.container-sec {
    padding: 14px;
  }

  .contact__card {
    padding: 6vw 7vw !important;
  }

  .contact__top-info {
    padding: 8vw 8vw 5px 0;
  }

  .card-detail {
    display: flex;
    flex-direction: column;
  }

  .card-detail p {
    width: 100%;
  }

  .contact__row > .card-info > h1 {
    font-size: 50px;
  }

  .contact-detail > div {
    display: flex;
    flex-direction: row;
    padding: 0 15px 15px 0;
  }

  .card-detail-image {
    margin: 0;
    padding: 0 0;
    width: 0;
    height: 0;
  }

  .card-detail > .card-info,
  .card-detail > .card-detail-image {
    flex: 0;
  }
}
