.navbar {
  background: transparent;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: absolute;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 80px;
}

.container {
  z-index: 1;
  width: 100%;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}

.navbar-logo {
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 20%;
}

.navbar-icon {
  margin-right: 0.5rem;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
}

/* .nav-item { */

/* height: 80px; */

/* border-bottom: 2px solid transparent; */

/* } */

/* .nav-item:hover {
  border-bottom: 2px solid var(--primary);
} */

.nav-links {
  display: flex;
  color: #333;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  /* padding: 0.5rem 2rem; */
  letter-spacing: 1px;
  height: 100%;
  margin: auto;
}

.navOverlay {
  display: flex;
  height: 30px;
  width: fit-content;
  margin: 0.5rem 2rem;
}

.navOverlay:hover {
  border-bottom: 2px solid var(--primary);
}

.fa-bars {
  color: var(--secondary);
}

.menu-icon {
  display: none;
}

.menu-icon > .MuiSvgIcon-root {
  color: var(--secondary-light);
}

.menu-icon > .MuiSvgIcon-root:hover {
  transform: scale(1.2);
  transition: all 0.3s ease;
}

@media screen and (max-width: 960px) {
  .navbar-container {
    padding-left: 28px !important;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 100px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: var(--dark-bg);
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
    align-items: start;
    justify-content: start;
  }

  .nav-links {
    color: #fff;
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #fff;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item {
    width: 100%;
    padding: 20px;
  }

  .nav-item:hover {
    border: none;
  }

  .navbar-logo {
    display: block;
    top: 0;
    left: 0;
    transform: translate(0%, 50%);
    min-width: 160px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: var(--secondary);
    font-size: 2rem;
  }

  .nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
    margin-top: 5vh;
  }
  
  .navOverlay {
    margin: auto;
  }
}
