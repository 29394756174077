.footer__section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer__container-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;

  /* padding: 40px; */
}

.footer__container {
  display: flex;
  flex-direction: column;
  padding: 15px 15px 80px 15px;
  width: inherit;
  justify-content: center;
  align-items: center;
}

.footer__container > img {
  height: 40px;
  margin-bottom: 40px;
  margin-top: 30px;
}

.footer__about-us {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.footer__about-us .sci {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  list-style-type: none;
}

.footer__about-us .sci li a {
  padding: 10px;
  color: #fff;
}

.footer__about-us .sci .MuiSvgIcon-root {
  color: #fff !important;
}

.footer__about-us .sci .MuiSvgIcon-root:hover {
  color: var(--primary) !important;
  transform: scale(1.3);
  transition: transform 0.3s ease;
}

.medium-icon {
  transform: scale(0.9);
}

.footer__links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sec {
  padding: 2px 35px;
  list-style-type: none;
}

.sec h4 {
  color: #fff;
  padding: 13px 13px 13px 0;
}

.footer__links .sec li {
  list-style-type: none;
  padding: 13px 13px 13px 0;
}

.footer__links .sec li a {
  font-size: 15px;
  color: #ccc;
  text-decoration: none;
  font-family: "Poppins-Light", sans-serif;
}

.footer__links .sec li a:hover {
  color: var(--primary);
}

.footer__copyright {
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1px;
  font-size: 11px;
}

.footer__copyright:hover {
  color: #000 !important;
}

.backToTop {
  padding: 10px 0 10px 10px;
  position: absolute;
  right: 0;
  margin-bottom: -484px;
}

.backToTop img {
  height: 100px;
}

@media screen and (max-width: 991px) {
  .footer__links {
    flex-direction: column;
  }

  .footer__container .sec {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .footer__quick-links {
    width: 100%;
  }

  .footer__quick-links > h4 {
    text-align: center;
    padding-bottom: 10px;
  }

  .footer__quick-links li {
    text-align: center;
    padding: 0 !important;
  }

  .sec h4 {
    padding: 13px 13px 13px 13px;
    text-align: center;
  }

  .backToTop {
    padding: 20px 0 20px 20px;
    position: absolute;
    right: 0;
    margin-bottom: -825px;
  }
}
